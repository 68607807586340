import React, { useState, useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

import { apiUrl } from '../services/http/http';
import './Profile.css';
import user_icon from './user_icon.png';
import 'react-confirm-alert/src/react-confirm-alert.css';
import auth from '../services/util/auth';
import masterListSource from './MasterList';

const ProfileList = () => {
  const [profiles, setProfiles] = useState();
  const [frameworkName, setFrameworkName] = useState('iscb');
  const [version, setVersion] = useState('3.0');
  const [loading, setLoading] = useState(false);
  const [profileWrapper, setProfileWrapper] = useState({
    height: '170px',
    overflow: 'hidden'
  });
  const [showFull, setShowFull] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        await fetch(
          `${apiUrl}/api/${frameworkName}/${version}/profiles/?_format=json&source=competencyhub&timestamp=`
        )
          .then(Response => Response.json())
          .then(findresponse => {
            setProfiles(findresponse);
            setLoading(false);
          });
      } catch (e) {
        console.log(e.message);
        setLoading(false);
      }
    };

    fetchData();
    if (showFull) {
      setProfileWrapper({ height: '100%' });
    } else {
      setProfileWrapper({ height: '170px', overflow: 'hidden' });
    }
  }, [version, frameworkName, showFull]);

  const changeViewMore = () => {
    if (showFull) {
      setShowFull(false);
    } else {
      setShowFull(true);
    }
  };

  const onSelectChange = e => {
    let frmVersion = masterListSource.filter(
      item => item.title === e.target.value
    )[0].version;
    if (frmVersion) {
      setFrameworkName(e.target.value);
      setVersion(frmVersion);
    } else {
      alert('wrong selection');
    }
    console.log(frameworkName + ' ' + frmVersion);
  };

  return (
    <div>
      <div style={{ width: '40%' }}>
        <div className="vf-form__item vf-stack">
          <label className="vf-form__label" htmlFor="vf-form__select">
            Select life science area
          </label>
          <select
            className="vf-form__select"
            id="vf-form__select"
            onChange={e => onSelectChange(e)}
          >
            <option value="iscb">
              Computational biology students and professionals
            </option>
            <option value="bioexcel">
              Computational biomolecular research professionals
            </option>
            <option value="permedcoe">
              Computational personalised medicine professionals
            </option>
          </select>
        </div>
      </div>
      <div className="vf-u-padding__top--400" />
      <div className="wrapper" style={profileWrapper}>
        <div className="vf-grid vf-grid__col-3">
          {profiles
            ? profiles.map((profile, index) => {
                if (!auth.currently_logged_in_user.is_logged_in) {
                  if (profile.publishing_status === 'Live') {
                    return (
                      <div key={index}>
                        <article className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                          {profile.image[0] ? (
                            <img
                              alt=""
                              src={profile.image[0].url}
                              className="vf-profile__image"
                              width={'auto'}
                              height={'auto'}
                              loading="lazy"
                            />
                          ) : (
                            <img
                              alt=""
                              src={user_icon}
                              className="vf-profile__image"
                            />
                          )}
                          <h4 className="vf-profile__title">
                            {profile.job_title ? (
                              <a
                                className="vf-profile__link"
                                href={`/framework/${frameworkName}/${version}/profile/view/${
                                  profile.id
                                }${profile.url_alias}`}
                              >
                                {profile.job_title}
                              </a>
                            ) : (
                              'Job title'
                            )}
                          </h4>
                        </article>
                      </div>
                    );
                  }
                } else {
                  return (
                    <div>
                      <article className="vf-profile vf-profile--very-easy vf-profile--small vf-profile--inline">
                        {profile.image[0] ? (
                          <img
                            alt=""
                            src={profile.image[0].url}
                            className="vf-profile__image"
                            width={'auto'}
                            height={'auto'}
                            loading="lazy"
                          />
                        ) : (
                          <img
                            alt=""
                            src={user_icon}
                            className="vf-profile__image"
                          />
                        )}
                        <h4 className="vf-profile__title">
                          {profile.job_title ? (
                            <a
                              className="vf-profile__link"
                              href={`/framework/${frameworkName}/${version}/profile/view/${
                                profile.id
                              }${profile.url_alias}`}
                            >
                              {profile.job_title}
                            </a>
                          ) : (
                            'Job title'
                          )}
                        </h4>
                      </article>
                    </div>
                  );
                }
                return null;
              })
            : ''}
          <div>
            {loading ? (
              <center>
                <img alt="progress" src="/progressbar.gif" />
                <h4>Loading resources</h4>
              </center>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      {profiles &&
      profiles.filter(profile => profile.publishing_status === 'Live').length >
        6 ? (
        <button
          className="vf-button vf-button--link"
          onClick={() => changeViewMore()}
          style={{ fontWeight: 'normal' }}
        >
          {showFull ? 'View less' : 'View more'}
        </button>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProfileList;
