import React from 'react';

const ElixirBanner = () => {
  return (
    <div>
      <h3 className="vf-u-sr-only">
        Explore competencies, training resources and career profiles
      </h3>
      <h3 className="vf-u-sr-only">ELIXIR banner</h3>

      {/* 
      Code pulling Elixir banner from VF.
      <section className="vf-u-fullbleed | vf-u-background-color-ui--grey--light">
        <div className="vf-body">
          <div
            className="vf-banner-elixir vf-banner"
            data-vf-js-banner-elixir
            data-vf-js-banner-elixir-logo=""
            data-vf-js-banner-elixir-name="EMBL-EBI Training"
            data-vf-js-banner-elixir-description="The EMBL-EBI Training Programme contributes to the ELIXIR Training Platform"
            data-vf-js-banner-elixir-link="https://www.elixir-europe.org/platforms/training"
          ></div>
        </div>
      </section> */}

      <section className="vf-u-fullbleed | vf-u-background-color-ui--grey--light">
        <div class="vf-body">
          <div
            class="vf-banner-elixir vf-banner"
            data-vf-js-banner-elixir=""
            data-vf-js-banner-elixir-logo=""
            data-vf-js-banner-elixir-name="EMBL-EBI Training"
            data-vf-js-banner-elixir-description="The EMBL-EBI Training Programme contributes to the ELIXIR Training Platform"
            data-vf-js-banner-elixir-link="https://www.elixir-europe.org/platforms/training"
          >
            <div className="vf-flag vf-flag--middle vf-flag--400">
              <div className="vf-flag__media">
                <img
                  src="https://ebi.emblstatic.net/web_guidelines/EBI-Framework/v1.2/images/logos/assorted/elixir_kitemark-60px.png"
                  alt=""
                />
              </div>
              <div className="vf-flag__body">
                <h4 className="vf-banner__text--lg">
                  <a
                    href="https://www.elixir-europe.org/platforms/training"
                    class="vf-link"
                  >
                    EMBL-EBI Training is part of the ELIXIR infrastructure
                  </a>
                </h4>
                <p className="vf-banner__text">
                  The EMBL-EBI Training Programme contributes to the ELIXIR
                  Training Platform
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ElixirBanner;
