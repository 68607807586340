import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import masterListSource from './MasterList';

const Breadcrumbs = () => {
  let location = useLocation();
  let pathname = location.pathname;
  let URL = pathname.split('/');
  let frameworkName = URL[2];
  let frameworkVersion = URL[3];
  let refProfileID = URL[7];

  const convertFrameworkName = name => {
    return masterListSource.filter(item => item.title === name)[0].new_desc;
  };

  const getBreadcrumbs = () => {
    if (
      pathname.includes('profiles/compare/guest/') &&
      pathname.includes('summary')
    ) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li className="vf-breadcrumbs__item">
            <Link
              to={`/framework/${frameworkName}/${frameworkVersion}/profiles/compare/guest/${refProfileID}`}
            >
              Compare career profiles
            </Link>
          </li>
          <li
            className="vf-breadcrumbs__item"
            aria-current="location"
            id="bc_location"
          >
            Summary of training resources
          </li>
        </>
      );
    } else if (pathname.includes('compare')) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Compare career profiles
          </li>
        </>
      );
    } else if (pathname.includes('profile/create/guest')) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Your profile
          </li>
        </>
      );
    } else if (pathname.includes('career-profiles')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            {convertFrameworkName(frameworkName)}
          </li>
        </>
      );
    } else if (pathname.includes('profile/map/guest')) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li className="vf-breadcrumbs__item">
            <Link
              to={`/framework/${frameworkName}/${frameworkVersion}/profile/view/guest`}
            >
              Profile overview
            </Link>
          </li>
          <li
            className="vf-breadcrumbs__item"
            aria-current="location"
            id="bc_location"
          >
            Assign competencies
          </li>
        </>
      );
    } else if (pathname.includes('profile')) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li
            className="vf-breadcrumbs__item"
            aria-current="location"
            id="bc_location"
          />
        </>
      );
    } else if (pathname.includes('competency')) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Competency details
          </li>
        </>
      );
    } else if (pathname.includes('export-framework')) {
      return (
        <>
          <li className="vf-breadcrumbs__item">
            <Link to={`/framework/${frameworkName}/${frameworkVersion}`}>
              {convertFrameworkName(frameworkName)}
            </Link>
          </li>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Export framework
          </li>
        </>
      );
    } else if (pathname.includes('manage-frameworks')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Manage frameworks
          </li>
        </>
      );
    } else if (pathname.includes('framework')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            {convertFrameworkName(frameworkName)}
          </li>
        </>
      );
    } else if (pathname.includes('all-training-resources')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Manage training resources
          </li>
        </>
      );
    } else if (pathname.includes('resource')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Training resource
          </li>
        </>
      );
    } else if (pathname.includes('documentation')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            API documentation
          </li>
        </>
      );
    } else if (pathname.includes('about')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            About
          </li>
        </>
      );
    } else if (pathname.includes('guidelines-course-creation')) {
      return (
        <>
          <li className="vf-breadcrumbs__item" aria-current="location">
            Guidelines for course creation
          </li>
        </>
      );
    }
  };

  return URL[0] === '' && URL[1] === '' ? (
    <span />
  ) : (
    <nav className="vf-breadcrumbs" aria-label="Breadcrumb">
      <ul className="vf-breadcrumbs__list | vf-list vf-list--inline">
        <li className="vf-breadcrumbs__item">
          <Link to={`/`}>Competency Hub</Link>
        </li>
        {getBreadcrumbs()}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
