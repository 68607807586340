import React, { useState, useEffect } from 'react';
import { Switch, Route, useLocation } from 'react-router-dom';
import { apiUrl } from '../services/http/http';
import masterListSource from './MasterList';

export const ProfilesCompareSummary = props => {
  const frameworkName = props.location.pathname.split('/')[2];
  const frameworkVersion = props.location.pathname.split('/')[3];
  const profileId = props.location.pathname.split('/')[6];

  const [framework, setFramework] = useState();
  const [frameworkInfo, setFrameworkInfo] = useState();
  const location = useLocation();
  const [summary, setSummary] = useState();

  let profileJobTitle = location.state.profileJobTitle;
  let includeSummary = location.state.includeSummary;
  //let refProfileID = location.state.refProfileID;
  var competencyView = '';
  var attribute_types = [];

  useEffect(() => {
    const fetchData = async () => {
      await fetch(
        `${apiUrl}/api/version_manager?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFrameworkInfo(findresponse);
        });

      await fetch(
        `${apiUrl}/api/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setFramework(findresponse);
        });

      await fetch(
        `${apiUrl}/api/profile_summary/${frameworkName}/${frameworkVersion}?_format=json&source=competencyhub`
      )
        .then(Response => Response.json())
        .then(findresponse => {
          setSummary(findresponse);
        });
    };
    fetchData();
  }, [profileId, frameworkVersion, frameworkName]);

  const findResources = competency_id => {
    if (includeSummary) {
      let test = summary?.map(item => {
        if (item && item.id === competency_id) {
          return item.resources.map(resource => {
            return (
              <li>
                <a
                  href={resource.resourceLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {' '}
                  {resource.resourceName}{' '}
                </a>
              </li>
            );
          });
        }
        return null;
      });
      return test;
    }
  };

  const generateSummary = () => {
    if (frameworkInfo) {
      frameworkInfo.map(info => {
        if (info.title.toLowerCase() === frameworkName) {
          info.attribute_types.map(
            attribute => (attribute_types[attribute.id] = attribute.title)
          );
        }
        return null;
      });
    }

    if (framework) {
      competencyView = framework.map(item =>
        item.domains.map(domain => (
          <div>
            <div className="vf-grid vf-grid__col-6">
              <div className="vf-grid__col--span-3">
                <h4>{domain.title}</h4>
              </div>
              <div className="vf-grid__col--span-2" />
            </div>
            {domain.competencies.map(competency =>
              includeSummary.indexOf(competency.id) !== -1 ? (
                <div id={competency.id} className="vf-grid vf-grid__col-6">
                  <div className="vf-grid__col--span-3">
                    <details className="vf-details">
                      <summary className="vf-details--summary">
                        {competency.title}
                      </summary>
                      {attribute_types.map(attribute_type => {
                        return (
                          <ul className="vf-list">
                            <li className="vf-list__item">
                              <strong> {attribute_type} </strong>
                              <ul className="vf-list vf-list--unordered">
                                {competency.attributes
                                  .filter(
                                    attribute =>
                                      attribute.type === attribute_type
                                  )
                                  .map(attribute => {
                                    return (
                                      <li className="vf-list__item">
                                        {attribute.title}
                                      </li>
                                    );
                                  })}
                              </ul>
                            </li>
                          </ul>
                        );
                      })}
                    </details>
                  </div>
                  <div className="vf-grid__col--span-1">Needs improvement</div>
                  <div className="vf-grid__col--span-2">
                    <ul>{findResources(competency.id)}</ul>
                  </div>
                </div>
              ) : (
                ''
              )
            )}
          </div>
        ))
      );
    }
  };

  // const handlePrint = () => {
  //   window.print();
  // };

  const shortDescFramework = () => {
    let item = masterListSource.filter(item => item.title === frameworkName)[0]
      .short_desc;
    return item.charAt(0).toUpperCase() + item.slice(1);
  };

  return (
    <div>
      {generateSummary()}
      <h1>Summary of training resources</h1>
      <p className="lead">
        Based on the comparison of your profile, the following are the suggested
        training resources to help develop your career, if you want to become a{' '}
        {profileJobTitle}
      </p>
      <hr />
      <div className="vf-grid vf-grid__col-6">
        <div className="vf-grid__col--span-3">
          <h3>{shortDescFramework()} Competencies</h3>
        </div>
        <div className="vf-grid__col--span-1">
          <h3>Status</h3>
        </div>
        <div className="vf-grid__col--span-2">
          <h3>Suggested training resources</h3>
        </div>
      </div>
      <hr />
      {competencyView}
    </div>
  );
};

export const SummaryPath = () => (
  <Switch>
    <Route
      exact
      path="/framework/:framework/:version/profiles/compare/guest/:refprofile/summary"
      component={ProfilesCompareSummary}
    />
  </Switch>
);

export default SummaryPath;
