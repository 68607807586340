import React from 'react';
import FrameworkButton from '../framework-button/FrameworkButton';

function FrameworkButtons({ frameworkDetails }) {
  let categories = [
    'Research-oriented students and professionals',
    'Research infrastructure professionals',
    'Clinical professionals'
  ];
  return (
    <>
      <div className="vf-u-padding__top--400" />
      <h3>{categories[0]}</h3>
      <section
        className="vf-card-container vf-card-container__col-3"
        style={{ margin: '0' }}
      >
        <div className="vf-card-container__inner">
          {frameworkDetails.map((framework, key) => {
            return framework.version !== 'draft' &&
              framework.category === categories[0] ? (
              <>
                <FrameworkButton
                  key={key}
                  desc={framework.desc}
                  sub_text={framework.sub_text}
                  card_link={framework.card_link}
                  category={framework.category}
                  new_title={framework.new_title}
                />
              </>
            ) : (
              ''
            );
          })}
        </div>
      </section>

      <h3>{categories[1]}</h3>
      <section
        className="vf-card-container vf-card-container__col-3"
        style={{ margin: '0' }}
      >
        <div className="vf-card-container__inner">
          {frameworkDetails.map((framework, key) => {
            return framework.version !== 'draft' &&
              framework.category === categories[1] ? (
              <>
                <FrameworkButton
                  key={key}
                  desc={framework.desc}
                  sub_text={framework.sub_text}
                  card_link={framework.card_link}
                  category={framework.category}
                  new_title={framework.new_title}
                />
              </>
            ) : (
              ''
            );
          })}
        </div>
      </section>

      <h3>{categories[2]}</h3>
      <section
        className="vf-card-container vf-card-container__col-3"
        style={{ margin: '0' }}
      >
        <div className="vf-card-container__inner">
          {frameworkDetails.map((framework, key) => {
            return framework.version !== 'draft' &&
              framework.category === categories[2] ? (
              <>
                <FrameworkButton
                  key={key}
                  desc={framework.desc}
                  sub_text={framework.sub_text}
                  card_link={framework.card_link}
                  category={framework.category}
                  new_title={framework.new_title}
                />
              </>
            ) : (
              ''
            );
          })}
          <div />
        </div>
      </section>
    </>
  );
}

export default FrameworkButtons;
